import request from "@/utils/request";
// 音乐列表
const getAudioList = (params) =>
  request.post("/boxing/web/musicGame/pageMusicList", params);
// 新增
const addMusic = (params) =>
  request.post("/boxing/web/musicGame/addMusic", params);
// 编辑基础信息
const editMusic = (params) =>
  request.post("/boxing/web/musicGame/modifyMusic", params);
// 编辑节奏点信息
const editRhythm = (params) =>
  request.post("/boxing/web/musicGame/batchModifyMusicRhythm", params);
// 删除音乐
const deleteMusic = (params) =>
  request.post("/boxing/web/musicGame/deleteMusic", params);
// 删除节奏点
const deleteRhythm = (params) =>
  request.post("/boxing/web/musicGame/deleteMusicRhythm", params);
// 获取节奏信息
const getRhythmList = (params) =>
  request.post("/boxing/web/musicGame/queryMusicRhythm", params);

// 获取excel模板
const getExcel = () =>
  request.post("/boxing/web/musicGame/importMusicRhythmFileUrl");
// 一键导入节奏点
const setExcel = (params) =>
  request.post("/boxing/web/musicGame/importMusicRhythm", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
// 设置音乐显隐
const setRhythmStatus = (params) =>
  request.post("/boxing/web/musicGame/modifyMusicStatus", params);
export {
  getAudioList,
  addMusic,
  editMusic,
  deleteMusic,
  editRhythm,
  deleteRhythm,
  getRhythmList,
  getExcel,
  setExcel,
  setRhythmStatus,
};
