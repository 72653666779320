<template>
  <div class="rhythm">
    <div class="top">
      <div class="querybox">
        <el-row :gutter="20">
          <el-col :span="6">
            <p>音乐名称:</p>
            <el-input
              v-model="queryParams.musicName"
              placeholder="请输入音乐名称"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <p>演唱人:</p>
            <el-input
              v-model="queryParams.musicSinger"
              placeholder="请输入演唱人"
            ></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="btnbox">
        <el-button
          type="primary"
          icon="el-icon-search"
          class="blue-btn"
          @click="search"
          >查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="gray-btn"
          @click="resetFn"
          >重置</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="green-btn"
          @click="addMusic"
          >新增</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="musicCover" label="音乐封面">
          <template slot-scope="{ row }">
            <div class="logo">
              <img :src="row.musicCover" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="musicName" label="音乐名称"> </el-table-column>
        <el-table-column prop="musicSinger" label="演唱人"> </el-table-column>
        <el-table-column prop="musicDurationText" label="音乐时长">
        </el-table-column>
        <el-table-column prop="createdTime" label="上传时间"> </el-table-column>
        <el-table-column prop="starLevel" label="星级">
          <template slot-scope="{ row }">
            <el-rate v-model="row.starLevel" disabled> </el-rate>
          </template>
        </el-table-column>
        <el-table-column prop="totalScore" label="总分"> </el-table-column>
        <el-table-column label="节奏点">
          <template slot-scope="{ row }">
            <el-button class="text-btn" type="text" @click="checkImg(row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              class="bluetext-btn"
              type="text"
              @click="goEdit(scope.row)"
              style="margin-right: 5px"
              >编辑
            </el-button>
            <el-popconfirm
              title="确认删除该音乐吗？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row.id)"
            >
              <el-button class="textred-btn" slot="reference">
                / 删除 /</el-button
              >
            </el-popconfirm>
            <el-button
              class="text-btn"
              type="text"
              @click="checkTest(scope.row)"
              style="margin-left: 5px"
              >试听
            </el-button>
            <el-popconfirm
              title="是否启用该音乐？"
              popper-class="customerdel-popover"
              @confirm="cancelMusic(scope.row, 0)"
              v-if="Number(scope.row.status) === 1"
            >
              <el-button class="textSpringGreen-btn" slot="reference">
                / 启用</el-button
              >
            </el-popconfirm>
            <el-popconfirm
              title="是否禁用该音乐？"
              popper-class="customerdel-popover"
              @confirm="cancelMusic(scope.row, 1)"
              v-if="Number(scope.row.status) === 0"
            >
              <el-button class="textDarkRed-btn" slot="reference">
                / 禁用</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
    <!-- 查看节奏点 -->
    <el-dialog
      :visible.sync="rhythmVisible"
      title="查看节奏点"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>查看节奏点</p>
        <span @click="handleClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="add-rhythm">
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="blue-btn"
          @click="addRhythm"
          >添加节奏点</el-button
        >
        <el-upload
          class="filter-item"
          ref="fileUpload"
          action="#"
          :http-request="httpRequestExcel"
          :on-change="selectChange"
          :show-file-list="false"
          :file-list="fileList"
          :limit="100"
          accept=".xls, .xlsx"
          :auto-upload="false"
        >
          <el-button type="primary" icon="el-icon-folder-add" class="green-btn"
            >一键导入</el-button
          >
        </el-upload>
        <el-button
          type="primary"
          icon="el-icon-download"
          class="gray-btn"
          @click="goDownload"
          >下载模板</el-button
        >
      </div>
      <div class="add-audio">
        <audio
          :src="resultFile"
          controls
          id="audioTag"
          @timeupdate="updateProgress"
          @play="audioPlay"
          @pause="audioPause"
          ref="audioPlayer"
        />
      </div>
      <el-form
        :model="musicForm"
        :rules="musicRules"
        ref="musicForm"
        class="demo-musicForm"
        hide-required-asterisk
      >
        <div class="list-scrollbar">
          <el-form-item class="rhythm-box">
            <el-row class="title-row">
              <el-col :span="5">左/右拳</el-col>
              <el-col :span="5">秒/s</el-col>
              <el-col :span="5">公斤/kg</el-col>
              <el-col :span="5">分数</el-col>
              <el-col :span="4">操作</el-col>
            </el-row>
            <el-row
              v-for="(item, index) in musicForm.musicRhythms"
              :key="index"
              class="item-box"
            >
              <el-col :span="5">
                <el-form-item
                  :prop="'musicRhythms.' + index + '.leftOrRight'"
                  :rules="musicRules.leftOrRight"
                >
                  <!-- <el-input
                  v-model="item.leftOrRight"
                  placeholder="请选择左右拳"
                ></el-input> -->
                  <el-select
                    v-model="item.leftOrRight"
                    placeholder="请选择左右拳"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item
                  :prop="'musicRhythms.' + index + '.hit'"
                  :rules="musicRules.hit"
                >
                  <el-input v-model="item.hit" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item
                  :prop="'musicRhythms.' + index + '.forceValue'"
                  :rules="musicRules.forceValue"
                >
                  <el-input
                    v-model="item.forceValue"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item
                  :prop="'musicRhythms.' + index + '.fraction'"
                  :rules="musicRules.fraction"
                >
                  <el-input
                    v-model="item.fraction"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <!-- <span @click="deleteRhythm(index)">删除</span> -->
                <el-popconfirm
                  title="确定删除该节奏点吗？"
                  popper-class="customerdel-popover"
                  @confirm="deleteRhythm(item.id)"
                  v-if="item.id"
                >
                  <el-button class="textred-btn" slot="reference"
                    >删除</el-button
                  >
                </el-popconfirm>
                <el-button
                  class="textred-btn"
                  v-else
                  @click="deleteLocalRhythm(index)"
                >
                  删除
                </el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"> 总分: {{ totalScore }} </el-col>
            </el-row>
          </el-form-item>
        </div>
        <el-form-item class="btnbox-rhythm">
          <el-button @click="handleClose()" class="gray-btn">取消</el-button>
          <el-button
            type="primary"
            @click="submitForm('musicForm')"
            class="blue-btn"
            :loading="submitLoading"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 试听 -->
    <el-dialog
      :visible.sync="testVisible"
      title="试听"
      width="30%"
      :before-close="handletestClose"
      destroy-on-close
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>试听</p>
        <span @click="handletestClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="audio-box">
        <span>{{ musicName }}-{{ musicSinger }}</span>
        <audio :src="audioUrl" controls ref="audioRef"></audio>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAudioList,
  getRhythmList,
  editRhythm,
  deleteRhythm,
  deleteMusic,
  getExcel,
  setExcel,
  setRhythmStatus,
} from "@/api/rhythm";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        musicName: "",
        musicSinger: "",
      },
      tableData: [],
      total: 0, //总数
      // 试听
      testVisible: false,
      audioUrl: "",
      musicName: "",
      musicSinger: "",
      // 节奏点编辑
      rhythmVisible: false,
      submitLoading: false,
      musicForm: {
        musicRhythms: [
          {
            leftOrRight: "",
            hit: "",
            forceValue: "",
            fraction: "",
          },
        ],
      },
      musicId: null,
      musicRules: {
        leftOrRight: [
          { required: true, message: "请选择左右拳", trigger: "change" },
        ],
        hit: [
          { required: true, message: "请输入该节奏点时间", trigger: "blur" },
          {
            required: true,
            validator: this.validator.moreThanZero,
            trigger: "blur",
          },
        ],
        forceValue: [
          {
            required: true,
            message: "请输入需要达到的公斤数",
            trigger: "blur",
          },
          {
            required: true,
            validator: this.validator.moreThanZero,
            trigger: "blur",
          },
        ],
        fraction: [
          {
            required: true,
            message: "请输入该节奏点所得分数",
            trigger: "blur",
          },
          {
            required: true,
            validator: this.validator.moreThanZero,
            trigger: "blur",
          },
        ],
      },
      options: [
        { label: "左拳", value: "left" },
        { label: "右拳", value: "right" },
      ],
      currentTime: 0,
      audioPlayStatus: false,
      resultFile: null,
      fileList: [],
      uploadFile: null,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  computed: {
    totalScore() {
      // console.log(this.musicForm);
      // const a = 1;
      const a = this.musicForm.musicRhythms.reduce((pre, next) => {
        return (pre += Number(next.fraction));
      }, 0);
      // console.log("a :>> ", a);
      return a === 0 ? "" : a;
    },
  },
  methods: {
    // 启用禁用
    async cancelMusic(item, status) {
      const params = {
        status: status,
        id: item.id,
      };
      await setRhythmStatus(params);
      // this.$refs[`${text}-popover-${index}`].doClose();
      this.init();
    },
    // 删除节奏点
    deleteLocalRhythm(index) {
      if (this.musicForm.musicRhythms.length === 1) {
        return;
      }
      this.musicForm.musicRhythms.splice(index, 1);
      // console.log("this.mu :>> ", this.musicForm.musicRhythms);
    },
    async goDownload() {
      const res = await getExcel();
      // console.log("res :>> ", res);
      window.open(res.result);
    },
    // 选择文件
    selectChange(file) {
      const { raw } = file;
      this.uploadFile = raw;
      this.$refs.fileUpload.submit();
      // console.log("raw :>> ", raw);
    },
    async httpRequestExcel() {
      // console.log("file :>> ", this.uploadFile);
      let formData = new FormData();
      formData.append("file", this.uploadFile);
      const res = await setExcel(formData);
      console.log("res :>> ", res);
      const list = res.result.map((el) => {
        return {
          ...el,
          musicId: this.musicId,
        };
      });
      this.musicForm.musicRhythms.push(...list);
    },
    // 监听键盘事件
    handleKeyDown(event) {
      if (!this.audioPlayStatus) return;
      if (event.key === "ArrowLeft") {
        this.musicForm.musicRhythms.push({
          leftOrRight: "left",
          hit: parseFloat(
            Math.round(this.currentTime * Math.pow(10, 3)) / Math.pow(10, 3)
          ),
          forceValue: 10,
          fraction: 10,
          musicId: this.musicId,
        });
      }
      if (event.key === "ArrowRight") {
        this.musicForm.musicRhythms.push({
          leftOrRight: "right",
          hit: parseFloat(
            Math.round(this.currentTime * Math.pow(10, 3)) / Math.pow(10, 3)
          ),
          forceValue: 10,
          fraction: 10,
          musicId: this.musicId,
        });
      }
    },
    //获取音乐列表
    async init() {
      const res = await getAudioList(this.queryParams);
      this.tableData = res.result.records;
      this.tableData.map((el) => {
        const m = Math.floor(el.musicDuration / 60);
        const s = Math.floor(el.musicDuration % 60);
        el.musicDurationText = `${m < 10 ? "0" + m : m}:${
          s < 10 ? "0" + s : s
        }`;
      });
      this.total = res.result.total;
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.musicSinger = "";
      this.queryParams.musicName = "";
      this.queryParams.pageNum = 1;
      this.init();
    },
    // 查看节奏点
    async checkImg(row) {
      const res = await getRhythmList({ id: row.id });
      this.musicId = row.id;
      this.musicForm.musicRhythms = res.result.sort((a, b) => {
        return a.hit - b.hit;
      });
      this.resultFile = row.musicUrl;
      // console.log("res :>> ", res);
      this.rhythmVisible = true;
    },
    handleClose() {
      this.rhythmVisible = false;
    },
    checkTest(row) {
      this.audioUrl = row.musicUrl;
      this.musicName = row.musicName;
      this.musicSinger = row.musicSinger;
      this.testVisible = true;
    },
    handletestClose() {
      this.testVisible = false;
    },
    // 删除音乐
    async cancelOrder(id) {
      // const params = {
      //   agreementName: item.agreementName,
      //   id: item.id,
      // };
      await deleteMusic({ id });
      this.$message.success("音乐删除成功");
      this.init();
    },
    // 新增
    addMusic() {
      this.$router.push("/addMusic");
    },
    // 编辑
    goEdit(row) {
      this.$router.push({
        path: "/editMusic",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    // 添加节奏点
    addRhythm() {
      this.musicForm.musicRhythms.push({
        leftOrRight: "",
        hit: "",
        forceValue: "",
        fraction: "",
        musicId: this.musicId,
      });
    },
    updateProgress(event) {
      // console.log("event :>> ", event.target.currentTime);
      this.currentTime = event.target.currentTime;
    },
    audioPlay() {
      const audioPlayer = this.$refs.audioPlayer;
      audioPlayer.play().then(() => {
        audioPlayer.blur(); // 使audio元素失焦
      });
      // this.$emit("updatePlay", true);
      this.audioPlayStatus = true;
    },
    audioPause() {
      this.audioPlayStatus = false;
    },
    // 删除节奏点
    async deleteRhythm(id) {
      await deleteRhythm({ id });
      this.$message.success("删除成功");
      const res = await getRhythmList({ id: this.musicId });
      this.musicForm.musicRhythms = res.result;
    },
    // 批量编辑节奏点
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          await editRhythm(this.musicForm.musicRhythms);
          this.$message.success("编辑节奏点成功！");
          this.rhythmVisible = false;
          this.submitLoading = false;
          this.init();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rhythm {
  .add-rhythm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    .green-btn {
      margin: 0 8px;
    }
  }
  .add-audio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .btnbox-rhythm {
    display: flex;
    justify-content: flex-end;
    .el-button {
      width: 100px;
    }
  }
  ::v-deep .list-scrollbar {
    width: 100%;
    margin-bottom: 20px;
    max-height: 500px;
    overflow-y: scroll;
    .el-col-24 {
      text-align: right;
      padding: 10px 40px;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: bold;
    }
    .rhythm-box {
      > .el-form-item__content {
        border: 1px solid #dbdfdf;
      }
    }

    .el-form-item__error {
      white-space: nowrap;
      padding: 0;
    }
  }
  ::v-deep .title-row {
    background: #f5f6f6;
    border-radius: 2px;
    box-sizing: border-box;
    height: 44px;
    border-bottom: 1px solid #dbdfdf;
    .el-col-4,
    .el-col-5 {
      text-align: center;
      height: 44px;
      font-size: 14px;
      color: #696969;
      line-height: 44px;
    }
    .el-col-5 {
      border-right: 1px solid #dbdfdf;
    }
  }
  ::v-deep .item-box {
    border-bottom: 1px solid #dbdfdf;
    display: flex;
    align-items: center;
    .el-col-5 {
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #dbdfdf;
      .el-input {
        width: 120px;
        text-align: center;
        .el-input__inner {
          text-align: center;
          height: 32px;
          line-height: 32px;
        }
      }
    }
    .el-col-4 {
      // padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      span {
        font-size: 16px;
        color: #ee3a3a;
        line-height: 21px;
        cursor: pointer;
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000d;
    .querybox {
      width: 90%;
      ::v-deep .el-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-col {
          display: flex;
          align-items: center;
          p {
            color: #4e5969;
            line-height: 22px;
            margin-right: 16px;
            white-space: nowrap;
          }
          .el-input,
          .el-range-editor {
            width: 256px;
          }
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #86909c;
          }
          .el-input__inner,
          .el-range-input {
            background: #f7f8fa;
            border: none;
            color: #86909c;
          }
          .tabul {
            margin: 16px 0;
            li {
              margin-right: 24px;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .btnbox {
      display: flex;
      // flex-direction: column;
      padding-bottom: 10px;
      .el-button {
        padding: 5px 0;
        width: 84px;
        margin-right: 12px;
      }
    }
  }
  .content {
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
    }
  }
  .audio-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-size: 16px;
      color: #131313;
      margin-bottom: 20px;
    }
  }
}
</style>
